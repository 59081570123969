<template>
  <div>
    <div style="position: sticky;top: 0;z-index: 999;">
      <Goback goback_title="订单详情" />
    </div>
    <div class="water_mask">
      <div v-if='isShow'>
        <div style="text-align:center;margin-top:150px">该职位已下架</div>
      </div>
      <div v-else style="background-color: #f5f5f5;">
        <div class="index_details">
          <van-swipe :autoplay="autoplay" :interval="interval" :duration="duration" circular="true">

            <van-swipe-item v-for="(item, index) in date.banner" :key="index">
              <div class="swiper-item">
                <img :src="item" />
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
        <div class="details_word">
          <div class="details_word_tyep">{{ date.type }}</div>
          <div class="details_word_exp_type" v-if="date.exp_type > 0">
            <template v-if="date.exp_type == 1">
              一手单：{{ date.exp_type_one_name }}
            </template>
            <template v-if="date.exp_type == 2">
              二手单
              <template v-if="date.exp_type_two">
                ：{{ date.exp_type_two }}
              </template>
            </template>
          </div>
          <div class="details_word_title">
            <div>{{ date.job_title }}</div>
          </div>
          <div class="details_word_money">
            {{ date.start_salary }}-{{ date.end_salary }}<span>元/月</span>
            <div v-if="total != 0 && total != null">奖励：{{ total }}元</div>
          </div>
          <div class="details_word_for" v-if="date.label && date.label.length != 0">
            <div v-for="(item, index) in date.label" :key='index'>{{ item }}</div>
          </div>
          <div class="details_word_last_right remaining">剩余名额 <span class="remaining_num">{{ date.usable_number
              }}</span>
          </div>
          <div class="details_word_last">
            <div class="details_word_address">
              <i class="iconfont icon-weizhi"></i>
              <div class="details_word_address_text">{{ date.address }}</div>
              <div class="details_word_last_copy" @click="handleCopy(date.address)">复制</div>
            </div>
            <div class="details_word_last_right">
              <div>职位编号：{{ date.position_number }}</div>
              <div>招聘时间：{{ date.recruitment_date }}</div>
              <div @click="handlePhone(date.customer_service)">咨询热线：{{ date.customer_service }}</div>
            </div>
          </div>
        </div>
        <div class="details_content">
          <div class="details_explain" v-if="date.store_remark && is_store_clerk">
            <div class="details_explain_title">经纪人备注</div>
            <div class="details_explain_content">
              <div class="details_explain_content_list">
                <div v-html="date.store_remark"></div>
              </div>
            </div>
          </div>
          <div class="details_explain" v-if='isCode == 1'>
            <div class="details_explain_title">订单详情</div>
            <div class="details_explain_content wages_describe">
              <div class="details_explain_content_list" :style="{ 'height': isHidden ? 'auto' : '' }">
                <div v-html="date.wages_describe"></div>
              </div>
              <div class="details_explain_hidden" @click="handleHidden">{{ !isHidden ? '收起' : '展开' }}
                <van-icon :name="!isHidden ? 'arrow-up' : 'arrow-down'" />
              </div>
            </div>
          </div>
          <div class="details_explain">
            <div class="details_explain_title">薪资待遇</div>
            <div class="details_explain_content">
              <div class="details_explain_content_list">
                <div v-html="date.salary"></div>
              </div>
            </div>
          </div>
          <div class="details_explain">
            <div class="details_explain_title">职位描述</div>
            <div class="details_explain_content">
              <div class="details_explain_content_list">
                <div v-html="date.job_description"></div>
              </div>
            </div>
          </div>
          <div class="details_explain">
            <div class="details_explain_title">任职要求</div>
            <div class="details_explain_content">
              <div class="details_explain_content_list">
                <div v-html='date.job_requirements'></div>
              </div>
            </div>
          </div>
          <div class="details_explain">
            <div class="details_explain_title">企业简介</div>
            <div class="details_explain_content">
              <div class="details_explain_content_list">
                <div v-html='date.company_profile'></div>
              </div>
            </div>
          </div>
        </div>
        <div class="details_foot">
          <div class="details_foot_content">
            <van-button type="info" size="small" class="vanbtn" @click.stop="send()">发送订单</van-button>
            <van-button type="info" size="small" class="vanbtn orders—btn-item"
              @click.stop="SignUp('帮他报名')">帮他报名</van-button>
            <van-button type="info" size="small" class="vanbtn orders—btn-item"
              @click.stop="SignUp('帮其他人报名')">帮其他人报名</van-button>
          </div>
        </div>
      </div>
    </div>

    <van-popup class="showContent" v-model="showContent" closeable>
      <div class="showContent_signItem" v-html="signItem"></div>
    </van-popup>
    <!-- 帮他报名/帮其他人报名弹窗 -->
    <van-dialog :closeOnPopstate="true" v-model="dialogVisible" :title="title" :showConfirmButton="false">

      <div style="max-height: 500px;overflow: auto;padding:10px;">
        <div class="orders_list">
          <div class="orders_list_left"><span>*</span>会员姓名</div>
          <input type="text" v-model="pram.real_name" placeholder="请输入会员姓名" />
        </div>
        <div class="orders_list">
          <div class="orders_list_left"><span>*</span>手机号</div>
          <input type="number" v-model="pram.phone" placeholder="请输入手机号" />
        </div>
        <div class="orders_list">
          <div class="orders_list_left"><span>*</span>身份证号</div>
          <div class="orders_list_right">
            <input type="text" v-model="pram.id_number" placeholder="请输入身份证号" @input="blurIdNumber" :maxlength="18" />
            <van-uploader :after-read="afterRead">
              <van-icon name="scan" size="26" color="#1989fa" />
            </van-uploader>
          </div>
        </div>
        <div class="orders_list">
          <div class="orders_list_left"><span>*</span>前往方式</div>
          <van-radio-group v-model="pram.way_to_go" direction="horizontal">
            <van-radio :name="item.type" v-for="(item, index) in radioList" :key="index">{{ item.title }}</van-radio>
          </van-radio-group>
        </div>
        <div class="orders_list" v-if="!isShowmemberSourceList">
          <div class="orders_list_left"><span>*</span>会员来源</div>
          <van-radio-group v-model="pram.member_source" direction="horizontal">
            <van-radio style="margin-bottom: 5px;" :name="item.status" v-for="(item, index) in memberSourceList"
              :key="index">{{ item.title }}</van-radio>
          </van-radio-group>
        </div>
      </div>
      <div class="orders_tips" v-if="operaLimit">{{ operaLimitMessage }}</div>
      <van-button style="width: 50%;" type="default" @click="onCancel">取消</van-button>
      <van-button style="width: 50%;" type="info" :disabled="isDisabled" @click="isShowconfirm?confirm():''">
        确定
        <template v-if="isDisabled && operaLimit && operaLimit != 'order_lock'">
          ({{countdown}}秒)
        </template>
      </van-button>
    </van-dialog>

  </div>
</template>

<script>
import { Toast,Dialog} from 'vant';
import {
  positionInfo, getPositionKey, getKehuDetail, qyToolHelpEnroll, hasMemberSource, uploadFile, ocrIdcard,GetBrokerEnrollCheck
} from '../../api/test'
import Goback from "@/components/home/goback.vue";
import { getUserInfo } from "../../utils/base";
export default {
  components: { Goback },
  data() {
    return {
      vertical: false,
      autoplay: 3000,
      interval: 5000,
      duration: 1000,
      detailBanner: [],
      mask: false,
      isFriend: 1, //判断是否是推荐好友,
      isCode: 1,
      isTrue: false, //是否禁用
      radio: 0,
      show: false,
      radioSourse: '10',
      isHidden: false,
      isLog: '',
      isReturn: false,
      isOne: 0,
      is_store_clerk: false,
      showContent: false,
      signItem: '',
      isShow: false,
      date: {},
      total: '',
      avatarUrl: '',
      nickName: '',
      dialogVisible: false,
      id: '',
      pram: {
        enterprise_position_id: '',
        real_name: '',
        phone: '',
        id_number: '',
        way_to_go: 10,
        member_source: 10,
        domicile: '',
        nation: ''
      },
      radioList: [
        { type: 20, title: "自行到场" },
        { type: 10, title: "门店集合" }
      ],
      memberSourceList: [
        { status: 10, title: "非网络渠道" },
        { status: 20, title: "抖音" },
        { status: 30, title: "58" },
        { status: 40, title: "boss" },
        { status: 50, title: "公众号" },
        { status: 60, title: "小程序" },
        { status: 70, title: "高德" },
        {status:80,title:"快手"},
      ],
      isShowmemberSourceList: true,
      title: '帮他报名',
      isShowconfirm:true,
      isDisabled: false,
      operaLimit: false,
      operaLimitMessage: '',
      timer: null,
      countdown: 5
    }
  },
  created() {
    this.pram.enterprise_position_id = this.$route.params.strItem;
    this.id = this.pram.enterprise_position_id
  },
  
  destroyed(){
    console.log(321)
    clearInterval(this.timer)
  },
  mounted() {
   
    this.getDetails();
    window.scrollTo(0, 0);
  },
  methods: {
    async handleCopy(e) {
      try {
        const textToCopy = e
        await navigator.clipboard.writeText(textToCopy);
        Toast('复制成功')
      } catch (error) {
        Toast('复制失败')
      }
    },
    async getShow() {
      let res = await getKehuDetail({
        qy_out_user_id: this.getUserId(),
        type: 0,
        visit_type: 1,
      });
      if (res.code == 200) {
        this.pram.real_name = res.data.user_detail.real_name
        this.pram.id_number = res.data.user_detail.id_number
        this.pram.phone = res.data.user_detail.phone
        this.pram.domicile = res.data.user_detail.domicile
        this.blurIdNumber()
        await this.getUserSignUpStatus()
        this.dialogVisible = true
      }
    },
    async afterRead(file) {
      let that = this
      Toast.loading({
        message: '身份证识别中...',
        forbidClick: true,
      });
      const form = new FormData()
      form.append('file', file.file)
      // 此时可以自行将文件上传至服务器
      let res = await uploadFile(form)
      if (res.code == 200) {
        ocrIdcard({ img_path: res.data.file_full_path }).then(request => {
          if(request.code==200){
            if(request.data.errcode==0){
              that.pram.id_number = request.data.id
              that.pram.real_name = that.pram.real_name ? that.pram.real_name : request.data.name
              that.pram.nation = request.data.nationality + '族'
              that.pram.domicile = request.data.addr
              that.blurIdNumber()
            }else{
              Dialog.alert({
                title: '未能识别身份证',
                message: '请重新拍摄或上传',
                confirmButtonColor:'#1989fa'
              }).then(() => {
                // on close
              });
            }
          }
        })
      }
    },
    blurIdNumber(){
      if(this.pram.id_number&&this.pram.id_number.length===18){
        let reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        if(reg.test(this.pram.id_number) == false){
          Toast('身份证号码有误')
        }else{
              // 显示加载提示
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        hasMemberSource({id_number:this.pram.id_number}).then(res=>{
        if(res.code==200){
          this.isShowmemberSourceList=res.data.has_member_source
          setTimeout(() => {
        // 隐藏加载提示
            Toast.clear();
          }, 500);
        }
      })
        }
    
      }else if(!this.pram.id_number||this.pram.id_number.length<18){
        this.isShowmemberSourceList=true
      }
    },
    onCancel() {
      this.dialogVisible = false
    },
    async SignUp(type) {
      this.isShowmemberSourceList = true
      if (type == '帮他报名') {
        this.getShow()
        this.title = '帮他报名'
      } else if (type == '帮其他人报名') {
        this.title = '帮其他人报名'
        this.pram = this.$options.data().pram
        this.pram.enterprise_position_id = await this.id
        await this.getUserSignUpStatus()
        this.dialogVisible = true
      }
      
      this.pram.enterprise_position_id = await this.id
    },
    
    async getUserSignUpStatus(){
      const res = await GetBrokerEnrollCheck({
        enterprise_position_id: this.pram.enterprise_position_id
      })
      if(res.code === 200){
        if(res.data.status == 'order_lock'){
          this.isDisabled = true
        }
        this.operaLimit = res.data.status  || false
        this.operaLimitMessage = res.data.msg
      }
    },
    confirm() {
      
      if (this.isDisabled) return
      // this.dialogVisible = false
      this.isShowconfirm=true
      let title = this.checkout()
      if (!title) {
        // 显示加载提示
        Toast.loading({
          message: '报名中...',
          forbidClick: true,
        });
        this.isShowconfirm=false
        qyToolHelpEnroll(this.pram).then(res => {
          if (res.code == 200) {
            Toast('报名成功')
            this.isShowconfirm=true
            setTimeout(() => {
              this.dialogVisible = false
              this.getDetails();
              Toast.clear();
            }, 500)
          }else{
            this.isShowconfirm=true
          }
          // 禁用按钮并开始倒计时
          if (this.operaLimit) {
            this.startCountdown()
          }
        })
      } else {
        // 禁用按钮并开始倒计时
        if (this.operaLimit) {
          this.startCountdown()
        }
        Toast(title);
      }
    },
    checkout() {
      let reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      let rePhone = /^1[3456789]\d{9}$/;
      if (!this.pram.real_name) {
        return '请输入会员姓名'
      } else if (!this.pram.phone) {
        return '请输入手机号'
      } else if (rePhone.test(this.pram.phone) == false) {
        return '手机号码错误'
      } else if (!this.pram.id_number) {
        return '请输入身份证号'
      } else if (reg.test(this.pram.id_number) == false) {
        return '身份证号码有误'
      } else if (!this.pram.way_to_go) {
        return '请选择前往方式'
      } else if (!this.isShowmemberSourceList && !this.pram.member_source) {
        return '请选择会员来源'
      }
    },
    send() {
      getPositionKey({ position_id: this.pram.enterprise_position_id }).then(res => {
        if (res.code == 200) {
          let details = {};
          details.scene = res.data
          wx.invoke('sendChatMessage', {
            msgtype: "miniprogram", //消息类型，必填
            enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
            miniprogram:
            {
              appid: "wx3bb67ab45cae2155",//小程序的appid，企业已关联的任一个小程序
              title: this.date.job_title, //小程序消息的title
              imgUrl: this.date.logo,//小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
              page: `pages/home/pages/details2/details2.html?details=${JSON.stringify(details)} `, //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
            },
          }, function (res) {
            if (res.err_msg == 'sendChatMessage:ok') {
              //发送成功
            }
          })
        }
      })
    },

    handleHidden() {
      this.isHidden = !this.isHidden
    },
    handlePhone(e) {
      let tel = e
      if (tel == null) {
        Toast('暂无电话')
        return
      }
      window.location.href = `tel:${tel}`;
    },
    handleSpace(date) {
      if (date) {
        return date.replace(/&nbsp;/g, "")
      } else {
        return date
      }
    },
    getUserId() {
      let userInfo = getUserInfo();
      let qy_out_user_id = userInfo.qy_out_user_id;
      return qy_out_user_id;
    },
    //获取详情数据
    async getDetails() {
      let res = await positionInfo({
        id: this.pram.enterprise_position_id,
        qy_out_user_id: this.getUserId(),
      });
      if (res.code == 200) {
        let date = res.data;
        let sum = 0,
          sum2 = 0,
          sum3 = 0,
          sum4 = 0
        date.applets_membership_refund = JSON.parse(date.applets_membership_refund)
        date.recommender_bonus = JSON.parse(date.recommender_bonus)
        date.recommender_commissions = JSON.parse(date.recommender_commissions)
        date.recommender_superior_commissions = JSON.parse(date.recommender_superior_commissions)
        date.label = JSON.parse(date.label)
        if (date.applets_membership_refund != null) {
          if (date.applets_membership_refund.length != 0) {
            date.applets_membership_refund.forEach((v, i) => {
              if (v.price != null) {
                sum += parseInt(v.price);
              }
            })
          }
        }
        if (date.recommender_bonus != null) {
          if (date.recommender_bonus.length != 0) {
            date.recommender_bonus.forEach((v, i) => {
              if (v.price != null) {
                sum2 += parseInt(v.price);
              }
            })
          }
        }
        if (date.recommender_commissions != null) {
          if (date.recommender_commissions.length != 0) {
            date.recommender_commissions.forEach((v, i) => {
              if (v.price != null) {
                sum3 += parseInt(v.price);
              }
            })
          }
        }
        if (date.recommender_superior_commissions != null) {
          if (date.recommender_superior_commissions.length != 0) {
            date.recommender_superior_commissions.forEach((v, i) => {
              if (v.price != null) {
                sum4 += parseInt(v.price);
              }
            })
          }
        }
        let total = parseInt(sum) + parseInt(sum2) + parseInt(sum3) + parseInt(sum4);
        date.store_remark = this.handleSpace(date.store_remark)
        date.wages_describe = this.handleSpace(date.wages_describe)
        date.salary = this.handleSpace(date.salary)
        date.job_description = this.handleSpace(date.job_description)
        date.job_requirements = this.handleSpace(date.job_requirements)
        date.company_profile = this.handleSpace(date.company_profile)
        this.date = date
        this.bonusTotal = sum2
        this.sumTotal = sum
        this.isShow = false
        this.total = total
      } else if (res.code == 400) {
        this.isShow = true
      }
    },
    
    startCountdown() {
      this.isDisabled = true 
      const timer = setInterval(() => {
        if (this.countdown <= 1) {
          clearInterval(timer)
          
          this.isDisabled = false
          this.countdown = 5
          return
        }
        this.countdown = this.countdown - 1
      }, 1000)
      this.timer = timer
    },
  },
}
</script>

<style lang="less" scoped>
/* pages/memeberRecord/memeberRecord.wxss */
page {
  background: #f5f5f5;
}

.position_number {
  height: 30px;
  color: #afafaf;
  line-height: 30px;
  font-size: 12px;
}

.van-swipe {
  width: 100%;
  height: 180px;
  border-radius: 20px;
  overflow: hidden;
}

.van-swipe-item {
  width: 100%;
  height: 100%;
}

.swiper-item img {
  width: 100%;
  height: 100%;
}


.details_word {
  padding: 15px;
  background-color: #ffffff;
  width: 90%;
  position: relative;
  top: -40px;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%);
  box-sizing: border-box;
  border-radius: 15px;
  overflow: hidden;
}

.details_word_tyep {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(318deg, #1F97EE 0%, #97CFF7 100%);
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  font-size: 15px;
  border-radius: 0 0 25px 0;
  padding: 4px 20px;
}

.details_word_exp_type {
  position: absolute;
  top: 0;
  right: 0px;
  background: linear-gradient(318deg, #1F97EE 0%, #97CFF7 100%);
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  font-size: 15px;
  border-radius: 0 0 0 25px;
  padding: 4px 20px;
  max-width: 160px;
}

.details_word_title {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.details_word_title>div {
  font-size: 18px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
}

.details_word_money {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 600;
  color: #FF4E3A;
  font-size: 17px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.details_word_money>div {
  margin-left: 10px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  font-size: 13px;
}

.details_word_money>span {
  font-size: 13px;
  font-weight: normal;
}


.details_word_for {
  overflow: hidden;

  /* margin: 10px 0 20px; */
}

.details_word_for div {
  color: #1F97EE;
  float: left;
  font-size: 13px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  background: #DFF1FF;
  padding: 3px 8px;
  margin-right: 10px;
}

.details_word_last {
  border-top: 1px solid #F5F5F5;
  padding: 10px 0 0;
}

.details_word_address {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  font-size: 14px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.details_word_address_text {
  flex: 1;
}

.details_word_last_copy {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #1F97EE;
  margin-left: 10px;
}

.details_word_last_right {
  margin-left: auto;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  font-size: 14px;
}

.details_word_last_right>div {
  margin-bottom: 8px;
}

.details_word_last_right>div:last-child {
  margin-bottom: 0;
}

.van-icon-location {
  vertical-align: middle;
  margin-right: 5px;
}

.van-icon-arrow {
  margin-left: 20px;
}



.details_content {
  width: 90%;
  margin: -30px auto;
  padding-bottom: 80px;
  box-sizing: border-box;
}

.details_explain {
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 10px;
}

.details_explain_title {
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  position: relative;
  padding: 15px 15px 15px 30px;
  border-bottom: 1px solid #F5F5F5;
}

.details_explain_title::after {
  content: '';
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 20px;
  background: #1F97EE;
  border-radius: 10px;
}

.details_explain_content {
  font-size: 14px !important;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  padding-bottom: 15px;
  padding: 15px;
}

.details_explain_content_list>div:first-child {
  margin-bottom: 5px;
}

.details_foot {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  border-top: 1px solid #ededed;
  background-color: #ffffff;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  z-index: 100;
}

.details_foot_content {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
  padding-right: 10px;
  box-sizing: border-box;
}

.wages_describe>div:first-child {
  overflow-y: auto;
  height: 150px;
  line-height: 23px;
  border-bottom: none;
}

.details_explain_hidden {
  display: flex;
  align-items: center;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  font-size: 14px;
  justify-content: center;
  margin-top: 15px;
  position: relative;
  z-index: 10;
}

.remaining {
  margin: 10px 0 20px;
  font-size: 13px;
}

.remaining_num {
  color: #ff603e;
  font-weight: 700;
}

.orders—btn-item {
  margin-left: 20px !important;
}

.orders_list_left {
  margin-right: 5px;
  flex-shrink: 0;
  width: 28%;
}

.orders_list_left span {
  color: #E25050;
  margin-left: 2px;
}

.orders_list {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  padding: 20px 0;
  box-sizing: border-box;
}

.orders_list:last-child {
  border-bottom: none;
}

.orders_list input {
  /* text-align: right; */
  /* flex: 1; */
  font-size: 15px;
  font-weight: 300;
  color: #ABABAB;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
}

.orders_list_right {
  display: flex;
  text-align: center;
}

.orders_list_right input {
  width: 80%;
}
.vanbtn{
  border-radius: 10px;
  padding: 16px;
  box-sizing: border-box;
}


.orders_tips{
  padding: 0 10px 10px;
  color: #F56C6C;
  font-size: 14px;
}
</style>
